import React, { useState, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { cnpjMask } from 'client/components/ToNormalize/ToNormalize';

import { useAuth } from 'contexts/auth';
import { InputReactSelectPaginate, InputWrapper } from './styles';
import providerRepository from '../../../../repositories/Providers';

const defaultPlaceholder =
  'Pesquisa por CNPJ/CPF, Razão Social ou Nome Fantasia';

const defaultStyles = (theme, height, width) => ({
  container: (base) => ({
    ...base,
    width: width || 'auto',
    height: height,
    minHeight: height,
  }),
  control: (base) => ({
    ...base,
    boxSizing: 'border-box',
    height: height,
    border: 'none',
    boxShadow: 'none',
    minHeight: '35px',
    background: 'transparent',
    justifyContent: 'flex-start',
    alignItems: 'center',
  }),
  placeholder: (provided) => ({
    ...provided,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    boxSizing: 'border-box',
    height: height,
    paddingTop: 0,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  input: (provided, state) => ({
    ...provided,
    boxSizing: 'border-box',
    margin: 0,
    padding: 0,
    height: height,
  }),
  dropdownIndicator: (base) => ({
    ...base,
    height: height,
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? theme.colors.branding1000 : 'inherit',
    '&:hover': {
      backgroundColor: state.isSelected
        ? theme.colors.branding900
        : 'rgb(222, 235, 255)',
    },
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  }),
});

export function SelectProvider({
  input,
  inputValue,
  meta,
  onChange,
  defaultValue = null,
  styles,
  placeholder = defaultPlaceholder,
  reloadTrigger,
  setReloadTrigger,
  disabled = false,
  isActive = true,
  height = '35px',
  width,
  ...rest
}) {
  const { companyId } = useAuth();
  const [currentInputKey, setCurrentInputKey] = useState(1);
  const theme = useTheme();

  useEffect(() => {
    if (reloadTrigger) {
      setReloadTrigger(false);
      setCurrentInputKey(currentInputKey + 1);
    }
  }, [reloadTrigger]);

  function handleLabel(provider) {
    return `${cnpjMask(provider.cpfCnpj)} | ${provider.companyName} | ${
      provider.tradingName
    }`;
  }

  async function handleFetch(search, loadedOptions, { page }) {
    try {
      const res = await providerRepository.getProviderPaginated({
        companyId,
        page,
        limit: 10,
        query: search,
      });

      const options = res.rows.map((provider) => ({
        label: handleLabel(provider),
        value: {
          ...provider,
          label: handleLabel(provider),
        },
      }));

      return {
        options,
        hasMore: page * 10 < res.count,
        additional: {
          page: page + 1,
        },
      };
    } catch (err) {
      console.log(err);
      return {
        options: [],
        hasMore: false,
      };
    }
  }

  return (
    <InputWrapper>
      <InputReactSelectPaginate
        value={inputValue}
        isDisabled={disabled}
        key={currentInputKey}
        debounceTimeout={500}
        loadOptions={handleFetch}
        cacheOptions
        defaultValue={defaultValue}
        placeholder={placeholder}
        onChange={(selectedOption) => {
          input.onChange(selectedOption.value);
        }}
        additional={{
          page: 1,
        }}
        noOptionsMessage={() => 'Sem opções'}
        loadingMessage={() => 'Carregando...'}
        styles={styles || defaultStyles(theme, height, width)}
        {...rest}
      />
    </InputWrapper>
  );
}
